/*--------------------------------------------------------------------------*
 * ハンバーガーメニュー
 *--------------------------------------------------------------------------*/
$(function(){
    $('[data-nav-trigger]').on('click', function(){
        $(this).toggleClass('active');

        var label = $('#menuLabel').text();
        if (label === 'MENU') {
            $('#menuLabel').text('CLOSE');
            $('[data-nav]').slideDown(300);
        } else {
            $('#menuLabel').text('MENU');
            $('[data-nav]').slideUp(200);
        }
    });

    if ($(window).width() <= 1024) {
        $('[data-gmenu-trigger]').on('click', function(){
            $('[data-gmenu-trigger]').toggleClass('active');
            var target = $(this).attr('data-gmenu-trigger');
            $('[data-gmenu="' + target + '"]').slideToggle(300);
            return false;
        });
        $('[data-fmenu-trigger]').on('click', function(){
            $('[data-fmenu-trigger]').toggleClass('active');
            var target = $(this).attr('data-fmenu-trigger');
            $('[data-fmenu="' + target + '"]').slideToggle(300);
            return false;
        });
        $('.p-gnav-item [data-moveTo]').on('click', function(){
            $('[data-nav-trigger]').removeClass('active');
            $('#menuLabel').text('MENU');
            $('[data-nav]').slideUp(200);
        });
    }
});
