$(function() {
  var listContents = $('#news-wrapper li').length;
  $('#news-wrapper').each(function(){
    var Num = 4;
    var gtNum = Num-1;

    $(this).find('#news-more').show();
    $(this).find('#news-close').hide();
    $(this).find('li:not(:lt('+Num+'))').hide();

    $('#news-more').click(function(){
      Num +=4;
      $(this).parent().find('li:lt('+Num+')').slideDown();

      if(listContents <= Num){
        Num = 4;
        gtNum = Num-1;
        $('#news-more').hide();
        $('#news-close').show();

        $('#news-close').click(function(){
          $(this).parent().find('li:gt('+gtNum+')').slideUp();
          $(this).hide();
          $('#news-more').show();
        });
      }
    });

    $('#news-close').click(function(){
      $('body, html').animate({ scrollTop: 0 }, 500);
      return false;
    });
  });
});