// if(navigator.userAgent.match(/MSIE 10/i) || navigator.userAgent.match(/Trident\/7\./) || navigator.userAgent.match(/Edge\/12\./)) {
//  $('body').on("mousewheel", function () {
//  event.preventDefault();
//  var wd = event.wheelDelta;
//  var csp = window.pageYOffset;
//  window.scrollTo(0, csp - wd);
//  });
//  }
$(window).on('load', function(){
    var windowHeight = $(window).height(); //ウインドウの高さを取得
    var winW = $(window).width();
    
    lineAnimation();

    $(window).on('scroll', function(){
        lineAnimation();
    });

    //スクロールに合わせてアニメーション
    function lineAnimation(){
        var scroll = $(window).scrollTop(); //スクロールの位置を取得 
        $('[data-animation]').each(function(){
            var position = $(this).offset().top;
            if (scroll > position - windowHeight){ //スクロール位置が要素の位置を過ぎたとき
                $(this).addClass('js-active'); //クラス「active」を与える
            }
        });
    }

    //パララックス
    // if ((winW >= 768) && ($('[data-scrollimage]').length > 0)) {
    //     var imgH = $('[data-scrollimage]').height();
    //     var imgW = $('[data-scrollimage]').width();
    //     var centerY = (1320 * (imgW / 2000) - imgH) / 2;
    //     var imgY = $('[data-scrollimage]').offset().top;
    //     $(window).on('scroll', function(){
    //         parallax();
    //     });

    //     function parallax(){
    //         var scroll = $(window).scrollTop() - 100; //スクロールの位置を取得 
    //         var diff = parseInt((imgY - scroll) * 0.1 - centerY);
    //         $('[data-scrollimage]').css({
    //             'background-position': 'center ' + diff + 'px'
    //         });
    //     }
    // }
});

$(function(){
    var target = $('[data-scrollimage]');
    var targetPosOT = target.offset().top;
    var targetFactor = 0.5;
    var windowH = $(window).height();
    var scrollYStart = targetPosOT - windowH;

    if ($(window).width() >= 768) {
        $(window).on('scroll',function(){
            var scrollY = $(this).scrollTop();
            if(scrollY > scrollYStart){
                target.css('background-position-y', (scrollY - targetPosOT) * targetFactor + 'px');
            }else{
                target.css('background-position','center top');
            }
        });
    }
});
