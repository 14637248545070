$(function(){
    $('#shop').slick({
        autoplay: true,
        arrows: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '170px',
        adaptiveHeight: true,
        prevArrow: '<button class="slick-prev slide-arrow arrow-prev"></button>',
        nextArrow: '<button class="slick-next slide-arrow arrow-next"></button>',

        responsive: [
            {
                breakpoint: 1025, //1024px以下の設定
                settings: {
                    centerPadding: '100px',
                }
            },{
                breakpoint: 900, //899px以下の設定
                settings: {
                    slidesToShow: 1,
                    centerPadding: '200px',
                }
            },{
                breakpoint: 768, //767px以下の設定
                settings: {
                    dots: true,
                    arrows: false,
                    slidesToShow: 1,
                    centerPadding: '45px',
                }
            }
        ]
    });
});

$(function(){
    $('.p-shop-items-copy').matchHeight();
})
