$(function(){
    window.sr = ScrollReveal();
    sr.reveal('.feedIn-01', {
        origin: 'bottom',
        distance: '0%',
        easing: 'ease-in-out',
        duration: 3000,
        opacity: 0,
        scale: 1
    });
    sr.reveal('.feedIn-02', {
        origin: 'bottom',
        distance: '30px',
        easing: 'ease-in-out',
        duration: 2000,
        opacity: 0,
        scale: 1
    });
});

$(function(){
    var headerHeight = $('.p-header').outerHeight();
    var urlHash = location.hash;
    if(urlHash) {
        $('body,html').stop().scrollTop(0);
        setTimeout(function(){
            var target = $(urlHash);
            var position = target.offset().top - headerHeight;
            $('body,html').stop().animate({scrollTop:position}, 500);
        }, 100);
    }
    $('[data-navTo]').click(function() {
        var href= $(this).attr('href');
        var target = $(href);
        var position = target.offset().top - headerHeight;
        $('body,html').stop().animate({scrollTop:position}, 500);   
    });
});

$(function(){
    $('#top-mv').slick({
        autoplay: true,
        dots: true,
        arrows: false,
        fade: true,
        slidesToShow: 1,
        pauseOnFocus: false,
        pauseOnHover: false,
        autoplaySpeed: 2000,
        speed: 1000
    });
});